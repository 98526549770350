import { useDispatch } from "react-redux";
import { fetchRegistration } from "../../redux/slices/clearinghouseRegistration";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Stroke from "../../assets/Stroke.svg";
import Shield from "../../assets/shield.svg";
import Info from "../../assets/info.svg";
import "./ClearinghouseRegistration.scss";
import { CHAT_ID, URI_API } from "../../data";
import axios from "axios";

export const ClearinghouseRegistration = () => {
  const dispatch = useDispatch();

  const [isSurnameEntered, setIsSurnameEntered] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      surname: "",
      name: "",
      patronymic: "",
      gender: "male",
      passport: "",
      dateIssue: "",
      unitCode: "",
      whomIssued: "",
      dateOfBirth: "",
      countryOfBirth: "",
      placeOfBirth: "",
      countryNationality: "",
      addressOfRegistration: "",
      addressIndex: "",
      addressOfResidence: "",
      addressIndexResidence: "",
      inn: "",
      snils: "",
      phone: "",
      email: "",
      passportScan: "",
      // passportScan2: "",
    },
  });
  const onSubmit = async data => {
    try {
      console.log("Отправляемые данные:", data);

      // Создаем объект FormData
      const formData = new FormData();
      formData.append("surname", data.surname);

      // Добавляем файл в FormData
      if (data.passportScan && data.passportScan.length > 0) {
        formData.append("passportScan", data.passportScan[0]); // Берем первый файл из массива
      } else {
        throw new Error("Файл passportScan не выбран");
      }

      // Создаем упрощенное сообщение для Telegram
      const message = `Поступила заявка о регистрации клинера`;

      // Отправляем сообщение в Telegram
      await axios.post(URI_API, {
        chat_id: CHAT_ID,
        text: message,
      });
      console.log("Message sent to Telegram");

      // Отправляем данные на сервер через Redux
      dispatch(fetchRegistration(formData))
        .then(result => {
          if (
            result.type ===
            "clearinghouseRegistration/fetchRegistration/fulfilled"
          ) {
            console.log("Регистрация успешно выполнена");
            setIsFormSubmitted(true);
            reset(); // Сбрасываем форму
          } else {
            console.error("Ошибка при регистрации:", result.error.message);
          }
        })
        .catch(error => {
          console.error("Ошибка при отправке данных:", error.message);
        });
    } catch (error) {
      console.error("Ошибка при обработке данных:", error.message);
    }
  };

  // const onSubmit = data => {
  //   console.log("Отправляемые данные:", data);
  //   // Создаем упрощенное сообщение для Telegram
  //   const message = `Поступила заявка о регистрации клинера`;
  //   // Отправляем сообщение в Telegram
  //   try {
  //     axios.post(URI_API, {
  //       chat_id: CHAT_ID,
  //       text: message,
  //     });
  //     console.log("Message sent to Telegram");
  //   } catch (error) {
  //     console.error(
  //       "Error sending message to Telegram:",
  //       error.response ? error.response.data : error.message
  //     );
  //   }
  //   dispatch(fetchRegistration(data))
  //     .then(result => {
  //       if (
  //         result.type ===
  //         "clearinghouseRegistration/fetchRegistration/fulfilled"
  //       ) {
  //         setIsFormSubmitted(true);
  //         reset(); // Сброс формы после успешной отправки
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Ошибка при отправке данных:", error);
  //     });
  // };

  // const onSubmit = data => {
  //   const formData = new FormData();

  //   // Добавляем текстовые данные
  //   for (const key in data) {
  //     if (key !== "passportScan" && key !== "passportScan2") {
  //       formData.append(key, data[key]);
  //     }
  //   }

  //   // Добавляем файлы
  //   formData.append("passportScan", data.passportScan[0]);
  //   formData.append("passportScan2", data.passportScan2[0]);

  //   // Отправка данных на сервер
  //   dispatch(fetchRegistration(formData))
  //     .then(result => {
  //       if (
  //         result.type ===
  //         "clearinghouseRegistration/fetchRegistration/fulfilled"
  //       ) {
  //         setIsFormSubmitted(true);
  //         reset(); // Сброс формы после успешной отправки
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Ошибка при отправке данных:", error);
  //     });
  // };

  const handleInputChange = event => {
    setIsSurnameEntered(event.target.value.trim() !== "");
  };

  const handleCloseModal = () => {
    setIsFormSubmitted(false);
  };

  return (
    <section className="registration-form">
      {isFormSubmitted && (
        <div className="modal">
          <div className="modal-content">
            <h2>Анкета отправлена</h2>
            <p>
              Ваша анкета успешно отправлена на модерацию после успешной
              проверки с Вами свяжется Менеджер и назначит Собеседование.
              Спасибо, что решили стать Клиннером нашего сервиса.
            </p>
            <button onClick={handleCloseModal} className="close-button">
              Закрыть
            </button>
          </div>
        </div>
      )}

      <div className="registration-title">Регистрация клинера</div>

      <div class="step-indicator">
        <div class="step active">
          <div class="circle">1</div>
          <span class="label">Авторизация</span>
        </div>
        <div class="line"></div>
        <div class="step active">
          <div class="circle">2</div>
          <span class="label">Документы</span>
        </div>
        <div class="line"></div>
        <div class="step">
          <div class="circle">3</div>
          <span class="label">Анкета</span>
        </div>
        <div class="line"></div>
        <div class="step">
          <div class="circle check">&#10003;</div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form-group">
          <div className="input-group">
            <label>Фамилия</label>
            <input
              {...register("surname", { required: "Фамилия обязательна" })}
              onChange={handleInputChange}
            />
            {isSurnameEntered && (
              <span className="check-icon">
                <img src={Stroke} alt="check" />
              </span>
            )}
            {errors.surname && (
              <span className="error">{errors.surname.message}</span>
            )}
          </div>
          {/* <div className="input-group">
            <label>Имя</label>
            <input {...register("name", { required: "Имя обязательно" })} />
            {errors.name && (
              <span className="error">{errors.name.message}</span>
            )}
          </div>
          <div className="input-group">
            <label>Отчество</label>
            <input {...register("patronymic")} />
          </div> */}
        </div>

        {/* <div className="form-group">
          <div className="gender-group">
            <label>
              <input
                type="radio"
                {...register("gender")}
                value="male"
                defaultChecked
              />
              Мужчина
            </label>
            <label>
              <input type="radio" {...register("gender")} value="female" />
              Женщина
            </label>
          </div>
        </div> */}

        {/* <div className="form-group">
          <div className="input-group">
            <label>Серия и номер паспорта</label>
            <input {...register("passport")} />
          </div>
          <div className="input-group">
            <label>Дата выдачи</label>
            <input {...register("dateIssue")} type="date" />
          </div>
          <div className="input-group">
            <label>Код подразделения</label>
            <input {...register("unitCode")} />
          </div>
        </div> */}

        {/* <div className="form-group">
          <div className="input-group">
            <label>Кем выдан (указать как в паспорте)</label>
            <input {...register("whomIssued")} />
          </div>
        </div>

        <div className="form-group">
          <div className="input-group">
            <label>Дата рождения</label>
            <input {...register("dateOfBirth")} type="date" />
          </div>
          <div className="input-group">
            <label>Страна рождения</label>
            <input {...register("countryOfBirth")} />
          </div>
          <div className="input-group">
            <label>Место рождения (укажите как в паспорте)</label>
            <input {...register("placeOfBirth")} />
          </div>
        </div>

        <div className="form-group">
          <div className="input-group">
            <label>Страна гражданство</label>
            <input {...register("countryNationality")} />
          </div>
        </div>

        <div className="from-goup-container">
          <h3>Адрес регистрации</h3>
          <div className="form-group">
            <div className="input-group">
              <label>Адрес регистрации</label>
              <input {...register("addressOfRegistration")} />
            </div>
            <div className="input-group">
              <label>Индекс</label>
              <input {...register("addressIndex")} />
            </div>
          </div>
        </div>

        <div className="from-goup-container">
          <h3>Адрес проживания</h3>
          <div className="form-group">
            <div className="input-group">
              <label>Адрес проживания</label>
              <input {...register("addressOfResidence")} />
            </div>
            <div className="input-group">
              <label>Индекс</label>
              <input {...register("addressIndexResidence")} />
            </div>
          </div>
        </div>

        <div className="from-goup-container">
          <h3>Другие документы</h3>
          <div className="form-group">
            <div className="input-group">
              <label>ИНН</label>
              <input {...register("inn")} />
            </div>
            <div className="input-group">
              <label>СНИЛС</label>
              <input {...register("snils")} />
            </div>
          </div>
        </div>

        <div className="from-goup-container">
          <h3>Контактные данные</h3>
          <div className="form-group">
            <div className="input-group">
              <label>Телефон</label>
              <input {...register("phone")} />
            </div>
            <div className="input-group">
              <label>Электронная почта</label>
              <input {...register("email")} type="email" />
            </div>
          </div>
        </div> */}

        <div className="from-goup-container">
          <h3>Загрузите сканы паспорта</h3>
          <div className="form-group">
            <div className="input-group">
              <label>Фото 1</label>
              <input
                {...register("passportScan", {
                  required: "Фото 1 обязательно",
                })}
                type="file"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <div className="form-info-text">
          <div className="form-info-text-intro">
            <img src={Info} width={24} alt="иконка" />
            Важно
          </div>
          Загруженные фото заполненных страниц паспорта, содержащих сведения о
          личности владельца паспорта о регистрации, должны быть без бликов и
          черных полос, Также убедитесь, что серия и номер паспорта хорошо
          различимы и читабельны.
        </div>

        <div
          className="form-info-text"
          style={{
            display: "flex",
          }}>
          <img
            src={Shield}
            width={24}
            alt="иконка"
            style={{
              marginRight: "10px",
            }}
          />
          <div>
            Мы гарантируем безопасность и сохранение ваших данных. Данные
            обробатываются и хранятся в соответствии с 152-ФЗ.
          </div>
        </div>

        <button className="from-goup-button" type="submit">
          Отправить
        </button>
      </form>
    </section>
  );
};
